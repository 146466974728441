<template>
  <main class="container flex h-screen w-screen flex-col items-center justify-center">
    <div class="w-full max-w-[450px] rounded-3xl border border-gray-100 bg-white p-16 shadow-2xl shadow-black/10">
      <Logo class="h-8" />

      <form @submit.prevent="submit" class="mt-4 flex flex-col">
        <h2 class="mt-4 text-2xl font-medium">Login</h2>
        <input type="email" v-model="email" placeholder="Enter your email address" class="mt-2 rounded-lg border-gray-300" required autocomplete="email" />
        <input
          type="password"
          v-model="password"
          placeholder="Enter password"
          class="mt-2 rounded-lg border-gray-300"
          required
          autocomplete="current-password" />
        <Errors v-if="errorMessage" :errors="errorMessage" />
        <Button size="lg" class="mt-2" type="submit">Login</Button>
        <NuxtLink to="/forgot-password" class="mt-4 text-center text-sky-900 hover:underline" target="_blank">Forgot password?</NuxtLink>
      </form>
    </div>
  </main>
</template>
<script setup>
  definePageMeta({
    layout: 'blank'
  })

  const router = useRouter()
  const client = useSupabaseClient()
  const user = useSupabaseUser()

  const email = ref('')
  const password = ref('')
  const errorMessage = ref('')

  const submit = async () => {
    const { data, error } = await client.auth.signInWithPassword({
      email: email.value,
      password: password.value
    })

    if (error) {
      errorMessage.value = error.message
      return
    }

    if (data) {
      router.push('/account')
    }
  }

  onMounted(() => {
    if (user.value) {
      router.push('/account')
    }
  })
</script>
